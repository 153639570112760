<template>
  <b-card>
    <validation-observer ref="accountRules" tag="form">
      <b-form class="mt-1" @submit.prevent>
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              Data Kegiatan Sekolah
            </h5>
          </b-col>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
              <b-form-group label="Tahun Akademik" label-for="academic_year_id"
                :state="errors.length > 0 ? false : null">
                <v-select id="academic_year_id" v-model="dataPrestasi.academic_year_id"
                  :reduce="(student_id) => student_id.id" placeholder="Tahun Akademik"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="academicYear" label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Tanggal Mulai" label-for="start_date">
              <validation-provider #default="{ errors }" name="start_date" rules="required">
                <b-form-input id="start_date" v-model="dataPrestasi.start_date" type="date"
                  :state="errors.length > 0 ? false : null" placeholder="01-01-2022" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tanggal Selesai" label-for="end_date">
              <validation-provider #default="{ errors }" name="end_date" rules="required">
                <b-form-input id="end_date" v-model="dataPrestasi.end_date" type="date"
                  :state="errors.length > 0 ? false : null" placeholder="01-01-2022" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
          <b-col md="6">
            <b-form-group label="Type Event" label-for="type">
              <validation-provider #default="{ errors }" name="type" rules="required">
                <b-form-input id="type" v-model="dataPrestasi.type" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Penyelenggaran" label-for="organizer">
              <validation-provider #default="{ errors }" name="organizer" rules="required">
                <b-form-input id="organizer" v-model="dataPrestasi.organizer"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="category" rules="required">
              <b-form-group label="Status" label-for="category" :state="errors.length > 0 ? false : null">
                <v-select id="category" v-model="dataPrestasi.category" :reduce="(category) => category.value"
                  placeholder="Pilih Kategori" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dataCategory" label="text" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
              @click="formSubmitted">
              Submit
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
              @click="() => $router.go(-1)">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm, BButton,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
// import { codeIcon } from './code'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BForm,
    BButton,
  },
  data() {
    return {
      required,
      email,
      size,
      dataPrestasi: {
        academic_year_id: '',
        start_date: '',
        end_date: '',
        type: '',
        organizer: '',
        status: '',
      },
      dataCategory: [
        { value: 1, text: 'Rencana' },
        { value: 2, text: 'Terlaksanan' },
      ],
      listSiswa: [],
      academicYear: [],
    }
  },
  created() {
    this.getSiswa()
    this.getAcademicYears()
  },
  computed: {
  },
  methods: {
    async getSiswa() {
      try {
        const response = await this.$http.get('/students')
        this.listSiswa = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getAcademicYears() {
      try {
        const response = await this.$http.get('/academicyears')
        this.academicYear = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Academic Not Success')
        console.log(err)
      }
    },
    async formSubmitted() {
      try {
        const dataFormPreastasi = new FormData()
        Object.keys(this.dataPrestasi).forEach(key => {
          dataFormPreastasi.append(key, this.dataPrestasi[key])
        })
        await this.$http.post('/achivements', dataFormPreastasi)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Prestasi Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-prestasi' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Prestasi Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        this.$router.push({ name: 'data-prestasi' })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
